<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement bg-white innerPage-banner">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6"> 
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap">
                            <div class="bdPage">
                                <h2>Publish Idea</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/dashboard">Home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Publish Idea</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="rightSearch">
                                <a href="javascript:void(0)" class="min-110 d-flex align-items-center justify-content-between gray_btn medium">Public <i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white publishIdea space-footer">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6">
                        <form class="publishPublicIdea">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="f-15 medium mb-2">Idea Title</label>
                                        <input type="text" class="form-control" placeholder="Title" name="">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="d-flex align-items-center justify-content-between mb-2">
                                            <label class="f-15 medium">Idea Title</label>
                                            <ul class="tags d-flex align-items-center justify-content-end mb-0">
                                                <li><a href="javascript:void(0)">B</a></li>
                                                <li><a href="javascript:void(0)"><i>I</i></a></li>
                                                <li><a href="javascript:void(0)"><vue-feather size="17px" type="bar-chart-2"></vue-feather></a></li>
                                                <li><a href="javascript:void(0)"><vue-feather size="17px" type="paperclip"></vue-feather></a></li>
                                                <li><a href="javascript:void(0)"><vue-feather size="17px" type="link-2"></vue-feather></a></li>
                                                <li><a href="javascript:void(0)"><img src="assets/images/tradeChart.svg" alt="Icon" title="Bar Chart"></a></li>
                                            </ul>
                                        </div>
                                        <textarea class="form-control" placeholder="Description"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="form-group">
                                        <label class="f-15 medium mb-2">Type</label>
                                        <select class="form-control form-select">
                                            <option>Analysis</option>
                                            <option>Educational</option>
                                            <option>Beyond Technical Analysis</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="form-group">
                                        <label class="f-15 medium mb-2">Classification</label>
                                        <select class="form-control form-select">
                                            <option>Trend Analysis</option>
                                            <option>Harmonic Pattern</option>
                                            <option>Chart Pattern</option>
                                            <option>Technical Indicator</option>
                                            <option>Wave Analysis</option>
                                            <option>Gann</option>
                                            <option>Fundamental Analysis</option>
                                            <option>Beyond Technical</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="form-group">
                                        <label class="f-15 medium mb-2">Position</label>
                                        <select class="form-control form-select">
                                            <option>Buy</option>
                                            <option>Sell</option>
                                            <option>Neutral</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label class="f-15 medium mb-2">Share</label>
                                        <ul class="share d-flex align-items-center">
                                            <li>
                                                <label class="customCheckbox mb-0">Facebook
                                                    <input type="checkbox" name="">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <label class="customCheckbox mb-0">Twitter
                                                    <input type="checkbox" name="">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </li>
                                            <li>
                                                <label class="customCheckbox mb-0">Instagram
                                                    <input type="checkbox" name="">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="form-group">
                                        <label class="customCheckbox pb-0">Make this trade idea Public
                                            <input type="checkbox" name="">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 mt-3">
                                    <div class="form-group">
                                        <a href="javascript:void(0)" class="grd_btn">Publish Public Idea</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
export default {
    data() {
        return {

        }
    },
    components: {
        bannerSlider:bannerslider,

    },     
}
</script>
